import FileUpload from 'o365.modules.FileUpload.ts';
import translate from 'o365.modules.translate.ts';
import DataObject from 'o365.modules.DataObject.ts';
//import alert from 'o365.controls.alert.js';
export default class DataObjectFileUpload{
    private _dataObject:DataObject;
    private _files:Array<File>;
    private _fileUpload:FileUpload;
    private _records:Array<any>;
    beforeUpload:Function;
    onCompleted:Function;
    progress:ProgressHandler;

    uploading:boolean = false;

    set files(pList){
        if(pList && pList.constructor === FileList){
            this._files = Array.from(pList);
        }else{
            this._files = pList;
        }
    }

    get files(){
        return this._files;
    }

    constructor(pDataObject:DataObject){
        this._dataObject = pDataObject;
        this._fileUpload = new FileUpload({
            url:`/api/file/upload/${this._dataObject.uniqueTable ?? this._dataObject.viewName}`,
            useChunks: true,
            viewName: this._dataObject.viewName
        });
 
        this.progress = new ProgressHandler();
    }

    async upload(pOptions:any = null, pData:any){
        if(pOptions.files){
            this.files = pOptions.files;
        }


        const vUploads = [];
      
        let vBeforeUploadParams;
        if(!this._files) return Promise.resolve();
        if(!this._files.length) return Promise.resolve();
        if(this._files.length === 0) return Promise.resolve();
        this.uploading = true;
        this.progress.start(pOptions);
        this.progress.message = `${translate('Starting to upload')} ${this.files.length} ${translate('files')}`;
        
        let vData = {};
        if (this._dataObject.masterDetails.isSet) {
            vData = Object.assign(vData, this._dataObject.masterDetails.getMasterDetailRowForInsert());
        }
        if(this.beforeUpload) vBeforeUploadParams = this.beforeUpload.call(this,...arguments);
        if(vBeforeUploadParams === false) return Promise.resolve();
        if(vBeforeUploadParams && typeof vBeforeUploadParams == "object"){
            Object.keys(vBeforeUploadParams).forEach(key=>{
                vData[key] = vBeforeUploadParams[key];
            })
        }
        vBeforeUploadParams = null;
        if(pOptions && pOptions.beforeUpload) vBeforeUploadParams = pOptions.beforeUpload.call(this,...arguments);
        if(pOptions && vBeforeUploadParams === false) return Promise.resolve();
        if(pOptions && vBeforeUploadParams && typeof vBeforeUploadParams == "object"){
            Object.keys(vBeforeUploadParams).forEach(key=>{
                vData[key] = vBeforeUploadParams[key];
            })
        }
        if(pData){
            vData = Object.assign(pData,vData);
        }
        this._records = [];
        for (let i = 0; i < this._files.length; i++) {
            let vFile = this._files[i];

            vUploads.push(this.uploadFile2(vFile,vData))
 
        }

        return Promise.all(vUploads).then((data)=>{
            this.uploading = false;
            
            if(pOptions.onCompleted) pOptions.onCompleted.call(this,...arguments);
            if(this.onCompleted) this.onCompleted.call(this,...arguments);
            this.progress.message = `${translate('Upload complete')}`;
            this._records = [];
             if(this._dataObject.dynamicLoading.enabled){
                this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data,{skip:0});
            }
            this._dataObject.setCurrentIndexByPrimKey(data[data.length-1][0][0]['PrimKey']);
            this.progress.completeUpload();
            return data;
        }).catch(ex=>{
            //wht to do ?
        
            this.progress.message = `${translate('Error')}`;
            this.uploading = false;
        });


    }

    cancel(){
        this.uploading = false;
        this.files = null;
        this.progress.message = `${translate('Canceling upload')}`;
        this._records.forEach(rec=>{
            if(rec.primKey){
                this._dataObject.cancelChanges(rec.item.index);
            }else{
                this._dataObject.deleteItem(rec.item);
            }
        });
        this._records = [];
        this._fileUpload.abort();
    }

    async createOrUpdateRecord(pFile:File,pData){
        if(pData.hasOwnProperty("PrimKey")){
            this.progress.message = `${translate('Updating record')}: ${pFile.name} `;
        }else{
            this.progress.message = `${translate('Creating record')}: ${pFile.name}`;
        }
        
        const vData = Object.assign(pData, {
                FileName:pFile.name,
                FileSize:pFile.size,

        });
        let vRow = null;
        if(pData.hasOwnProperty("PrimKey")){
            vRow = this._dataObject.storage.getItemByPrimKey(pData["PrimKey"])
            //will be update operatrion
            this._dataObject.storage.updateItem(vRow.index,vData,false);
            this._records.push({
                item:vRow,
                primKey:pData["PrimKey"]
            });
            return Promise.resolve(vRow);
        }else{
            //create
            vRow = this._dataObject.createNew(pData,false);
            this._records.push({
                item:vRow,
                primKey:null
            });
            return this._dataObject.save(vRow.index).then(_=>{
                return vRow;
            });
        }

        
    }

    uploadFile2(pFile,pData){
        return this.createOrUpdateRecord(pFile,pData).then(row=>{
            this.progress.message = `${translate('Uploading file: ')} ${pFile.name}`;
            return this._uploadFile(pFile,pData,row);
        })
    }

    _uploadFile(pFile:File,pData, pRecord:any){
        return this._fileUpload.upload({
            file:pFile,
            data:pData,
            uploadRef: pRecord.PrimKey,
            onProgress:(e)=>this.progress.updateProgress(pFile,e)
        }).then(ref=>{
            this._dataObject.storage.updateItem(pRecord.index,{FileRef:ref['FileRef']},false);
            this.progress.message = `${translate('Updating uploaded file')}`;
            return this._dataObject.save(pRecord.index);
        });
    }

    uploadFile(pFile,pData){
       

        return this._fileUpload.upload({
                file:pFile,
                data:pData,
                onProgress:(e)=>this.progress.updateProgress(pFile,e)
            }).then(ref=>{
                if(ref.hasOwnProperty("FileRef")){
                    pData["FileRef"] = ref['FileRef'];
                    return this.createOrUpdateRecord(pFile,pData);
                    
                }else{
                    return this._dataObject.recordSource.refreshRowByPrimKey(ref['uploadRef']);
                }
                
            }).catch(er=>{
                //alert(er);
                
                this.progress.updateError(pFile,er);
               throw(er);
            });
    }

}

class ProgressHandler{
    private items:Map<string,any>;
   // items:FileList;
    private _currentProgress:number;
    private _totals:object;
    private _message:string;

    inProgress:boolean=false;
    
    uploaded:number = 0;
    total:number = 0;
    error:string = null;
    set message(pMessage){
        this._message = pMessage;
        this.fireOnProgress();
    }
    get message(){
        return this._message;
    }


    onProgress:Function;
    get currentProgress(){
        return this._currentProgress;
    }

    get totals(){
        return {
            files:this.items,
            total:this.total,
            uploaded:this.uploaded,
            progress:this.uploaded !== 0?Math.min(Math.round((this.uploaded/this.total)*100),100):0,
            completed:this.uploaded===this.total,
            error:this.error,
            message:this.message
        }
    }

    set currenProgress(pVal){
        this._currentProgress = pVal;
    }
    get progress(){
        return this.items
    }
    constructor(){
        this.items = new Map();
    }

    start(pOptions){
        this.reset();
        this.inProgress = true;
       
        for (let i = 0; i < pOptions.files.length; i++) {
            let vFile = pOptions.files[i];
            this.items.set(vFile,new Progress(vFile));
            this.total += vFile.size;

        }
        this.onProgress = pOptions.onProgress;

    }
    updateError(pFile:string,pMessage:string){
        const vItem = this.items.get(pFile);
        vItem.error = pMessage;
        this.error = pMessage;
        this.message = null;
        this.inProgress = false;
         this.fireOnProgress();
    }
    updateProgress(pFile,pParams){

            const vItem = this.items.get(pFile);
            vItem.uploaded = Math.min(pParams.loaded,vItem.total);
            this.uploaded = 0;
        
            this.items.forEach((val,file)=>{
                this.uploaded += val.uploaded;
                
            })
       

       // console.log(this.totals.progress,this.totals.uploaded,this.totals.total);
        this.fireOnProgress();
    }

    fireOnProgress(){
        if(this.onProgress) this.onProgress.call(this,this.totals);
    }
    cancel(){
        this.reset();
    }

    completeUpload(){
        this.reset();
        this.inProgress = false;
    }

    reset(){
       
        this.items = new Map();
        

        this.total = 0;
        this.uploaded = 0;
        this.error = null;
   
   
    }
}

class Progress{
    private _uploaded:number = 0;
    name:string;
    path:string;
    total:number;
    error:string;
    
    set uploaded(pValue){
        if(this.total < pValue){
            this._uploaded = this.total;
        }else{
            this._uploaded = pValue;
        }
    }

    get uploaded(){
        return this._uploaded;
    }
   


    get completed(){
        return this.uploaded >= this.total;
    }
    get id(){
        if(this.path){
            return this.path + "/"+this.name;
        }

        return this.name;
    }

    constructor(pFile){
        this.total = pFile.size;
        this.name = pFile.name;
        this.path = pFile.path;
    }
}